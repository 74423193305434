export const UNIFIED_TESTIMONIALS = [
  {
    title: 'Easy to use!!',
    text: `The Skylight is awesome for displaying family photos!! It is incredibly easy to use!! Being able to email photos directly to the frame is an incredible innovation for these types of digital photo frames!!`,
    name: 'Lisa H.',
    location: '',
  },
  {
    title: 'Super helpful!',
    text: `We have 3 kids and have tried many different ways to share schedules, but this has worked the best. It's been super helpful for everyone (especially my husband) to see our schedule. The grocery list has been surprisingly helpful, and my kids love adding items to it. It's been two weeks and no one has asked me what time anything is. 🙌🏻`,
    name: 'Kathleen K.',
    location: '',
  },
  {
    title: 'Highly recommend',
    text: `My family members love that we can upload photos to my parents' skylight, and see them in person when we get together every Sunday for family dinner! The pictures are super clear, and I love the timer and editing functions as well. We highly recommend skylight!!!`,
    name: 'Melissa V.',
    location: '',
  },

  {
    title: `Coolest Gadget I've Ever Bought`,
    text: `We've used a shared calendar on our phones for years, but having it visible on the Skylight Calendar for everyone to see is perfect. The ability to add meals plans, grocery lists, and chores for my son make it all the better.`,
    name: 'Rob S.',
    location: '',
  },
  {
    title: 'Priceless',
    text: `It was very simple to set up. The memories that are brought back as the slide show continues are priceless. The grandchildren pictures that are sent fills you with so much love as if you were there.`,
    name: 'Gabe P.',
    location: '',
  },
]

export const CAL_TESTIMONIALS = [
  {
    title: 'Great addition to our home!',
    text: `"It’s been two weeks and no one has asked me what time anything is. This has been a sanity-saver for my busy family!"`,
    name: 'Elizabeth M.',
    location: 'Denver, CO',
  },
  {
    title: 'The kids love it!',
    text: `"Our kids can easily see what’s on the schedule and love checking off their chores! The Skylight helps us foster independence in our kids without giving them a device."`,
    name: 'Emily G.',
    location: 'Brentwood, CA',
  },
  {
    title: 'Must-have for busy families!',
    text: `"Our schedules are so busy between travel for work, two girls in sports and then their school events on top of that... this makes everything so much easier to manage!"`,
    name: 'Kerry R.',
    location: 'Nashville, TN',
  },

  {
    title: 'Thank you Skylight!',
    text: `"Having the whole family on the same page and organized is invaluable. It's been a great time management tool for my two boys as they learn how to plan, organize and prioritize their time."`,
    name: 'Candice P.',
    location: 'New York, NY',
  },
  {
    title: 'So helpful!',
    text: `"The Skylight Calendar is the best tool to keep our family organized, on-task, motivated, and accountable."`,
    name: 'Miranda S.',
    location: 'Oak Park, IL',
  },
]

export const FRAME_TESTIMONIALS = [
  {
    title: 'Super Easy',
    text: `“I love sharing pictures of my kids with multiple family members all at once. I don’t have to send multiple texts or emails and risk forgetting someone!”`,
    name: 'Erica T.',
    location: 'Canada',
  },
  {
    title: 'Beautiful Frame',
    text: `“Excellent addition to the living room, keeps those fond memories scrolling.”`,
    name: 'Paul L.',
    location: 'USA',
  },
  {
    title: 'Best Gift Ever',
    text: `“Couldn't be happier with the results of sending this frame loaded with pictures to my step-mom.”`,
    name: 'Millie S.',
    location: 'Canada',
  },

  {
    title: 'Skylight is the perfect gift',
    text: `“Our family is seriously obsessed! It started with gifts to my parents and grandparents, and now I have purchased 7!!! Everyone loves them so much!”`,
    name: 'Emily L.',
    location: 'USA',
  },
  {
    title: 'Awesome gift!',
    text: `It’s like visiting with family every day.`,
    name: 'Susan H.',
    location: 'USA',
  },
  {
    title: 'High quality photo frame',
    text: `"It is WORTH the money to help organize your digital and photo clutter."`,
    name: 'Terry B.',
    location: 'United Kingdom',
  },
  {
    title: 'Love it.',
    text: `"I have dementia and seeing pictures of my family has made all the difference helping me keep my memories alive."`,
    name: 'Timothy T.',
    location: 'USA',
  },
  {
    title: 'Brilliant',
    text: `"I purchased a Skylight to be able to enjoy all of our wedding photos in one frame. Now we can relive the fun and love of our special day EVERY DAY!"`,
    name: 'Amy P.',
    location: 'Australia',
  },
]

export const INTERNATIONAL_FRAME_TESTIMONIALS = [
  {
    title: 'Super Easy',
    text: `"Super easy to set up and figure out. I love that I can send a link to all of my siblings and their families to send pictures to my mom (nana). She gets so excited when new photos get sent to her ❤️"`,
    name: 'Substanance',
    location: 'Canada',
  },
  {
    title: 'Best Gift Ever',
    text: `"I adore my Skylight frame. It is by far the best gift I have ever received. I was always asking my daughter for more pictures of her family. She finally found this frame and now I have updated pictures any time I want. So easy to set up and it holds so many pictures and videos."`,
    name: 'Melanie',
    location: 'USA',
  },
  {
    title: 'Super Easy Set Up',
    text: `"Bought one for my grandpa and mum. Awesome it is so easy for everyone in the family to add to."`,
    name: 'Jessica',
    location: 'Canada',
  },

  {
    title: 'Skylight is the perfect gift',
    text: `"Very impressed with how easy to use, the picture resolution and how other family or friends can add photo's easily, the perfect gift for anyone older with memory issues."`,
    name: 'Chris',
    location: 'Canada',
  },
  {
    title: 'Awesome gift!',
    text: `"We gifted this to both sides of grandparents. They haven't stopped talking about how much they enjoy it and since some of our family do not use social media, it is a fantastic (and private) way to share pictures of our kids. Highly recommend!!"`,
    name: 'RP',
    location: 'Canada',
  },
  {
    title: 'Great way to stay connected',
    text: `"We live on the other side of the world from our friends and family; this is an amazing way to feel connected. It was easy to set up and even easier to share the link with family and friends!! I highly recommend this - and have done our family and friends."`,
    name: 'David',
    location: 'United Kingdom',
  },
  {
    title: 'Love it.',
    text: `"Truly a great product. Easy to use. Bought for both sets of grandparents for Christmas and now my wife wants one."`,
    name: 'Sean',
    location: 'USA',
  },
  {
    title: 'Brilliant',
    text: `"A fabulous concept where you can relive memories time and again along with enjoying surprise ones which pop up - a fabulous gift."`,
    name: 'SJ',
    location: 'Australia',
  },
  {
    title: 'My Grandparents love it!',
    text: `"My Grandparents wait for new pictures daily! It's precious. My whole family uploads almost every day."`,
    name: 'Kayleen',
    location: 'USA',
  },
  {
    title: 'Outstanding Product',
    text: `"Quick and easy set up. Excellent connectivity. I love this product."`,
    name: 'MC',
    location: 'Singapore',
  },
  {
    title: 'Best way to share photos without social media',
    text: `"Grandparents approve! We refrain from posting our sons picture on any social media platforms, so we got all grandparents a Skylight for Christmas and they all completely love and enjoy the frame being able to watch their first grand baby grow!"`,
    name: 'Brittany',
    location: 'USA',
  },
  {
    title: 'Huge hit & so fun!',
    text: `"The frames seemed to be a huge hit for everyone we gave the frame to and it's been so fun for us as the giver to share pictures. So cool to know it'll be like Christmas every time they click "new photos have been added" to their frames throughout the year!"`,
    name: 'Ellen',
    location: 'USA',
  },
  {
    title: 'Beautiful Gift!',
    text: `"Most beautiful gift ever! User friendly and convenient!"`,
    name: 'Vanessa',
    location: 'Canada',
  },
]
