import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Section = styled.div`
  margin: auto;
  background-color: ${(props) => props.theme.cream};
  text-align: left;
  .slick-slide div {
    outline: none !important;
  }
  .slick-slide {
    padding: 0px 0px 20px;
    width: 100%;
    @media (max-width: ${breakpoints.l}px) {
      padding: 40px 0px 0px;
      text-align: center;
    }
    @media (max-width: ${breakpoints.s}px) {
      width: 100%;
      padding: 20px 0px 0px;
    }
    @media (max-width: 420px) {
      height: 660px;
    }
  }
  .slick-list {
    padding: 0 20% 0 0;
  }
  .slick-center .text {
    @media (max-width: ${breakpoints.s}px) {
      min-width: 320px;
    }
  }
  .slick-center .imgContainer {
    opacity: 1;
  }
`
export const Header = styled.h4`
  display: inline;
  font-family: 'FilsonProBook';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.09px;
  text-align: center;
  color: ${(props) => props.theme.grayDark};
  border-bottom: solid 1.5px ${(props) => props.theme.coral};
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 0.08px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px 20px;
  }
`
export const Container = styled.div`
  display: flex;
  align-items: ${(props) => (props.isCalSocial ? 'flex-start' : 'center')};
  justify-content: ${(props) => (props.isCalSocial ? 'center' : 'space-around')};
  margin: 15px 15px;
  @media (max-width: ${breakpoints.l}px) {
    flex-wrap: wrap;
    height: auto;
    > div:first-child {
      margin-left: 0px;
      margin-right: 0px;
    }
    > div:nth-child(2) {
      margin-right: 0px;
    }
  }
  @media (max-width: 746px) {
    margin: 10px 20px;
  }
  @media (max-width: 640px) {
    margin: 10px 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    height: auto;
  }
`

export const Text = styled.div`
  font-family: 'filsonPro';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.grayDark};
  @media (max-width: 1310px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0.09px;
  }
`
export const Card = styled.div`
  padding: 40px 45px;
  border-radius: 25px;
  box-shadow: 0px 0px 17px rgba(68, 68, 68, 0.15);
  text-align: left;
  min-width: 320px;
  position: relative;
  @media (max-width: 1300px) {
    padding: 40px 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
    padding: 20px;
  }
  @media (max-width: 410px) {
    min-width: 250px;
  }
  @media (max-width: 350px) {
    min-width: 230px;
    padding: 20px 10px;
  }
`
export const CustomerName = styled.h2`
  font-family: 'FilsonProBook';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.grayDark};
  margin-bottom: 0px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: 0.08px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.09px;
  }
`

export const Arrow = styled(GatsbyImage)`
  margin: 0px 12px 0px 12px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  width: 55px;
  @media (max-width: ${breakpoints.s}px) {
    width: 40px;
  }
`

export const Check = styled(GatsbyImage)`
  margin-left: 10px;
  display: inline-block;
  width: 18px;
  @media (max-width: ${breakpoints.s}px) {
    width: 14px !important;
    height: 14px !important;
  }
`
export const Star = styled(GatsbyImage)`
  margin-bottom: 20px;
  display: inline-block;
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 10px;
  }
`

export const LogoContainer = styled.div`
  width: 80px;
  margin: 15px 15px 0px 0px;
  @media (max-width: ${breakpoints.s}px) {
    width: 52px;
  }
`

export const SliderContainer = styled.div`
  position: relative;
  z-index: 1;
  .slick-dots {
    position: initial;
    width: 800px;
    margin: auto;
    padding: ${(props) => (props.isCalSocial ? '15px 0px 40px' : '0px')};
    li {
      position: initial;
    }
  }
  .slick-dots {
    .slick-active button {
      background: #ffbb33;
    }
    button {
      background: rgba(255, 187, 51, 0.2);
      border-radius: 25px;
      width: 10px;
      height: 10px;
      ::before {
        display: none;
      }
    }
  }
`
export const LeftArrow = styled.div`
  overflow: hidden;
  position: absolute;
  top: 30%;
  left: 30px;
  z-index: 3;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  @media (max-width: 1100px) {
    top: 35%;
  }
  @media (max-width: ${breakpoints.s}px) {
    left: 0px;
    margin: 0px 0px;
  }
`
export const RightArrow = styled.div`
  position: absolute;
  top: 30%;
  right: 30px;
  z-index: 3;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  @media (max-width: 1100px) {
    top: 35%;
  }
  @media (max-width: ${breakpoints.s}px) {
    right: 0px;
    margin: 0px 0px;
  }
`
export const Title = styled.p`
  font-family: 'filsonProBold';
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-top: 0px;
  margin-bottom: 15px;
  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.09px;
  }
`
export const ReviewerDetails = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`
export const Location = styled(Text)`
  @media (max-width: ${breakpoints.xl}px) {
    padding: 0px;
    margin-top: 0px;
  }
`
export const SocialText = styled.p`
  font-family: FilsonProBook;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.09px;
  margin: 0px;
  @media (max-width: 1150px) {
    line-height: 140%;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
`
