import React, { Component } from 'react'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import {
  CAL_TESTIMONIALS,
  FRAME_TESTIMONIALS,
  INTERNATIONAL_FRAME_TESTIMONIALS,
  UNIFIED_TESTIMONIALS,
} from './constants'
import {
  CustomerName,
  Text,
  Card,
  Arrow,
  LogoContainer,
  Container,
  SliderContainer,
  LeftArrow,
  RightArrow,
  Title,
  ReviewerDetails,
  Check,
  Star,
  Location,
  SocialText,
} from './styles'

import { IS_FOREIGN_COUNTRY } from '../../utils/constants'

function TestimonialCard(props) {
  const { title, text, check, star, image, name, location } = props

  return (
    <Card>
      <Title>
        <Trans>{title}</Trans>
      </Title>
      <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
      <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
      <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
      <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
      <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
      <Text className="text">
        <SocialText>
          <Trans>{text}</Trans>
        </SocialText>
      </Text>
      <ReviewerDetails>
        <LogoContainer>
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="Happy Customer" />
        </LogoContainer>
        <div>
          <CustomerName>
            <Trans>{name}</Trans>
            <Check image={check.childImageSharp.gatsbyImageData} alt="Verified reviewer checkbox" />
          </CustomerName>
          <Location>
            <Trans>{location}</Trans>
          </Location>
        </div>
      </ReviewerDetails>
    </Card>
  )
}
class CalendarCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const { backImg, forwardImg, images, star, check, page } = this.props

    const TESTIMONIALS_OVERRIDE = {
      frame: IS_FOREIGN_COUNTRY ? INTERNATIONAL_FRAME_TESTIMONIALS : FRAME_TESTIMONIALS,
      unified: UNIFIED_TESTIMONIALS,
    }
    const reviews = TESTIMONIALS_OVERRIDE[page] || CAL_TESTIMONIALS
    return (
      <SliderContainer>
        <LeftArrow onClick={this.previous}>
          <Arrow image={backImg.childImageSharp.gatsbyImageData} alt="Back Arrow" />
        </LeftArrow>
        <Slider
          ref={(c) => (this.slider = c)}
          className="center"
          centerMode
          infinite
          centerPadding="60px"
          slidesToShow={3}
          speed={500}
          slidesToScroll={1}
          initialSlide={0}
          dots
          responsive={[
            {
              breakpoint: 1100,
              settings: {
                dots: false,
                arrows: false,
                centerMode: true,
                centerPadding: '300px',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 992,
              settings: {
                dots: false,
                arrows: false,
                centerMode: true,
                centerPadding: '200px',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 740,
              settings: {
                dots: false,
                arrows: false,
                centerMode: true,
                centerPadding: '60px',
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 450,
              settings: {
                dots: false,
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1,
              },
            },
          ]}
        >
          {reviews.map((review, i) => (
            <React.Fragment key={i}>
              <Container>
                <TestimonialCard
                  title={review.title}
                  text={review.text}
                  check={check}
                  star={star}
                  image={images[i]}
                  name={review.name}
                  location={review.location}
                />
              </Container>
            </React.Fragment>
          ))}
        </Slider>
        <RightArrow onClick={this.next}>
          <Arrow image={forwardImg.childImageSharp.gatsbyImageData} alt="Forward Arrow" />
        </RightArrow>
      </SliderContainer>
    )
  }
}
TestimonialCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  star: PropTypes.any,
  name: PropTypes.string,
  image: PropTypes.any,
  check: PropTypes.any,
  location: PropTypes.string,
}

CalendarCarousel.propTypes = {
  forwardImg: PropTypes.any,
  backImg: PropTypes.any,
  star: PropTypes.any,
  check: PropTypes.any,
  images: PropTypes.array,
  page: PropTypes.string,
}
export default CalendarCarousel
