import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Section = styled.div`
  background-color: ${(props) => props.theme.blueLight};
  padding: 60px 0px;
  position: relative;
  z-index: 10;
  @media (max-width: ${breakpoints.s}px) {
    padding: 40px 0px;
  }
  > div:nth-of-type(2) {
    > div:nth-of-type(2) {
      padding-right: 0px;
      @media (max-width: ${breakpoints.l}px) {
        padding-right: 55px;
      }
    }
    h2,
    p {
      max-width: 520px;
    }
  }
  > div:nth-of-type(3) {
    margin-top: 90px;
    @media (max-width: ${breakpoints.l}px) {
      margin-top: 50px;
    }
    > div:nth-of-type(2) {
      padding-right: 0px;
      @media (max-width: ${breakpoints.l}px) {
        padding-left: 55px;
      }
    }
    @media (max-width: ${breakpoints.s}px) {
      margin-top: 30px;
    }
  }
  div:nth-child(4) {
    flex-direction: row-reverse;
    p {
      max-width: 520px;
    }
    > div {
      @media (max-width: ${breakpoints.s}px) {
        align-self: baseline;
      }
    }
  }
  div:nth-child(6) {
    padding-top: 40px;
    flex-direction: column;
    h2,
    p {
      text-align: center;
      max-width: 700px;
      @media (max-width: ${breakpoints.m}px) {
        max-width: 90%;
      }
      margin: auto;
    }
    h2 {
      margin-top: 40px;
    }
    div {
      margin: auto;
      @media (max-width: ${breakpoints.s}px) {
        width: 100%;
        > div:first-child {
          margin-left: 5%;
        }
      }
    }
  }
`
export const Header = styled.h1`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  text-align: center;
  line-height: 53px;
  letter-spacing: 0.19px;
  color: ${(props) => props.theme.blueDark};
  margin: 0px;
  padding-bottom: 40px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.15px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 20px;
  }
  @media (max-width: 350px) {
    font-size: 28px;
  }
`
export const SecondaryHeader = styled.h2`
  font-family: 'P22MackinacProBook';
  font-size: 30px;
  text-align: ${(props) => props.align};
  line-height: 42px;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.blueDark};
  margin: 17px 0px;
  @media (max-width: ${breakpoints.xl}px) {
    margin: 0px;
    font-size: 26px;
    line-height: 26px;
  }
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    margin-top: 30px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.13px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: 20px;
  }
`
export const Text = styled.p`
  font-family: 'FilsonProBook';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.09px;
  text-align: ${(props) => props.align};
  color: ${(props) => props.theme.grayDark};
  margin: 10px 0px 0px;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    line-height: 26px;
    font-size: 18px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
    text-align: center;
  }
`
export const LogoContainer = styled.div`
  width: 450px;
  text-align: left;
  position: relative;
  z-index: 10;

  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 80%;
  }
`
export const Icon = styled(GatsbyImage)``
export const SubtitleIcon = styled(GatsbyImage)``
export const Row = styled.div`
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: ${breakpoints.l}px) {
    flex-wrap: wrap;
  }
  @media (max-width: 480px) {
    align-items: stretch;
  }
`
export const Card = styled.div`
  position: relative;
  z-index: 10;
  margin: 0px 60px;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.m}px) {
    margin: 30px 10%;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px 10%;
  }
`
export const Message = styled.p`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  text-align: center;
  line-height: 51px;
  letter-spacing: 0.19px;
  color: white;
  background-color: ${(props) => props.theme.blueDark};
  padding: 120px 25%;
  font-weight: normal;
  margin: 100px 0px 60px;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 10%;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 50px 0px 40px;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.15px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 30px 0px 20px;
  }
  @media (max-width: 350px) {
    font-size: 28px;
  }
`
export const ShapeImage = styled(GatsbyImage)`
  width: 253px;
`
export const ImageContainer = styled.div`
  position: absolute;
  top: 190px;
  right: 0px;
  z-index: 2;
  width: 253px;
  margin-right: 50px;
  @media (max-width: ${breakpoints.xl}px) {
    right: -20px;
    top: 220px;
  }
  @media (max-width: 1130px) {
    right: -30px;
    top: 220px;
  }
  @media (max-width: 1110px) {
    right: -30px;
    top: 200px;
  }
  @media (max-width: 1070px) {
    right: -30px;
    top: 190px;
  }
  @media (max-width: 1050px) {
    display: none;
  }
`
export const SubtitleContainer = styled.div`
  width: 22px;
  margin: 0px 2px;
  @media (max-width: 480px) {
    width: 20px;
  }
  @media (max-width: 375px) {
    width: 18px;
  }
  @media (max-width: 340px) {
    width: 16px;
  }
`

export const Name = styled.p`
  font-family: 'FilsonProBook';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.blueDark};
  padding-top: 20px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
`
export const SubtitleFlexbox = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 480px) {
    align-items: flex-end;
  }
`
export const HiddenOnDesktop = styled.div`
  display: none;
  @media (max-width: ${breakpoints.s}px) {
    display: contents;
  }
`
export const HiddenOnMobile = styled.div`
  display: contents;
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`
