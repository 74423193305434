export const ABOUT = [
  {
    sectionTitle: 'Our Mission to Connect Loved Ones',
    pOne: `Our team first came together as students at Harvard Business School, where we realized we had the same problem: we were all living far away from our families. We were eager to find a device that made it easy to share photos with our loved ones across the world… and not just to their phones, but into a beautiful physical display that would live in their home. We were surprised to find that the product we wanted didn’t exist, so we decided to create it.`,
    pTwo: `And thus Skylight was born, designed from the start to connect the digital and non-digital worlds, and to bring together families that might be many miles apart.`,
  },
  {
    sectionTitle: 'Simplifying Family Life',
    pOne: `As we spoke with our customers about their family’s needs, we realized we could give them more - the ability to simplify their lives so they can spend more time with their loved ones. That’s when we created Skylight Calendar, a simple solution to manage their family’s lives and schedules. Thousands of families now use this solution to communicate their events with each other, stay on track with chores, and keep their family running like a tight ship.`,
    pTwo: `We continue to build new solutions for families and are excited to have you on this journey with us.`,
  },
  {
    sectionTitle: 'Our Growth',
    pOne: `We have been overwhelmed by the response to Skylight, as hundreds of thousands of families have brought our products into their homes. We think often about the thousands of smiles that we are able to put on our customers’ faces each day, and it fills our hearts with purpose. That’s why we have been working hard every day to expand our team and perfect our products, so that we can continue to bring the magic of Skylight to as many families as we can.`,
    pTwo: `Today, we have more team members than ever before, and all of us are dedicated to our mission of connecting loved ones, young and old. We are so excited to continue this amazing journey, and welcome more of you into our Skylight family!`,
  },
]
