import React from 'react'

import { Helmet } from 'react-helmet'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Analytics from '../../utils/Analytics'
import CalendarTestimonials from '../../components/CalendarTestimonials'
import About from '../../components/About'
import SEO from '../../components/seo'
import { HideOverflow } from '../../utils/styles/global-styles'

class AboutPage extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed About Landing Page')
  }

  render() {
    return (
      <>
        <SEO
          title="About Us | Skylight"
          description="Skylight is on a mission to connect loved ones and simplify family life by creating accessible solutions that support the everyday needs of families."
        />
        <Helmet>
          <meta charset="UTF-8" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
          />
        </Helmet>
        <HideOverflow>
          <About />
          <CalendarTestimonials page="frame" title="Families Rave About Skylight Products" />
        </HideOverflow>
      </>
    )
  }
}

export default withTranslation()(AboutPage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "about"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
