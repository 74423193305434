import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'
import { ABOUT } from './constants'

import {
  Section,
  Header,
  SecondaryHeader,
  Text,
  LogoContainer,
  Icon,
  Row,
  Card,
  Message,
  ImageContainer,
  SubtitleContainer,
  Name,
  SubtitleFlexbox,
  SubtitleIcon,
  HiddenOnDesktop,
  HiddenOnMobile,
} from './styles'

const AboutUsPage = () => {
  const {
    missionImage,
    simplifyingImage,
    growthImage,
    growthImageMobile,
    shapeImage,
    subtitleOne,
    subtitleTwo,
  } = useStaticQuery(graphql`
    query AboutQuery {
      missionImage: file(relativePath: { eq: "about-our-mission.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      simplifyingImage: file(relativePath: { eq: "about-simplifying-family.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      growthImage: file(relativePath: { eq: "about-our-growth.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      growthImageMobile: file(relativePath: { eq: "about-our-growth-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      shapeImage: file(relativePath: { eq: "about-shape.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      subtitleOne: file(relativePath: { eq: "about-subtitle-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      subtitleTwo: file(relativePath: { eq: "about-subtitle-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const images = [missionImage, simplifyingImage, growthImage]
  const alt = ['Team in Office', 'Team at Zoo', 'Growing Team']
  return (
    <>
      <Section>
        <Header>
          <Trans>About Us</Trans>
        </Header>
        <ImageContainer>
          <Icon image={shapeImage.childImageSharp.gatsbyImageData} alt="Shape" />
        </ImageContainer>
        {ABOUT.map((about, i) => (
          <React.Fragment key={about.sectionTitle}>
            <Row>
              {i === 0 && (
                <SubtitleFlexbox>
                  <SubtitleContainer>
                    <SubtitleIcon
                      image={subtitleOne.childImageSharp.gatsbyImageData}
                      alt="Meeting with Customers"
                    />
                  </SubtitleContainer>
                </SubtitleFlexbox>
              )}
              {i === 1 && (
                <SubtitleFlexbox>
                  <SubtitleContainer>
                    <SubtitleIcon
                      image={subtitleTwo.childImageSharp.gatsbyImageData}
                      alt="At Malibu Wines Los Angeles"
                    />
                  </SubtitleContainer>
                </SubtitleFlexbox>
              )}
              {i === 2 ? (
                <HiddenOnMobile>
                  <LogoContainer>
                    <Icon image={images[i].childImageSharp.gatsbyImageData} alt={alt[i]} />
                  </LogoContainer>
                </HiddenOnMobile>
              ) : (
                <LogoContainer>
                  <Icon image={images[i].childImageSharp.gatsbyImageData} alt={alt[i]} />
                </LogoContainer>
              )}
              {i === 2 && (
                <HiddenOnDesktop>
                  <LogoContainer>
                    <Icon
                      image={growthImageMobile.childImageSharp.gatsbyImageData}
                      alt="Growing Team"
                    />
                  </LogoContainer>
                </HiddenOnDesktop>
              )}
              <Card>
                <SecondaryHeader align="left">
                  <Trans>{about.sectionTitle}</Trans>
                </SecondaryHeader>
                <Text align="left">
                  <Trans>{about.pOne}</Trans>
                </Text>
                <Text align="left">
                  <Trans>{about.pTwo}</Trans>
                </Text>
                {i === 2 && (
                  <Name>
                    <Trans>-Team Skylight</Trans>
                  </Name>
                )}
              </Card>
            </Row>
            {i === 1 && (
              <Row>
                <Message>
                  <Trans>
                    We believe Skylight will change the way families and friends share moments, and
                    hope you love our products as much as we do!
                  </Trans>
                </Message>
              </Row>
            )}
          </React.Fragment>
        ))}
      </Section>
    </>
  )
}

export default AboutUsPage
